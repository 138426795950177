.custon_table {
  border-collapse: collapse;
  margin: 10px;
}

.bordered {
  border-bottom: thick double #ff385c;
}
.custom-item-img {
  width: 150px;
  padding: 5px;
}

.custom_cart_complement {
  font-weight: 700;
  font-size: 12px;
  color: #999999;
}

.custom_product_title {
  font-weight: 700;
  font-size: 16px;
  color: #ff385c;
}
.custom_product_price {
  font-weight: 700;
  font-size: 16px;
}

.custom-minigrid {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 4px;
  border: 1px solid #d7d8da;
  border-radius: 10px;
  width: 50%;
}

.custom-minigrid td {
  text-align: center;
  vertical-align: middle;
}

.custom-minigrid tbody td {
  border: 1px solid #d7d8da;
  border-radius: 10px;
  min-width: 40px;
  height: 40px;
}

.custom-minigrid tbody td:not(:first-child) {
  padding: 5px;
  background-color: #f2f2f2;
}
.custom-minigrid-no-value {
  background-color: inherit !important;
}

.custom-minigrid thead td {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}

@media (prefers-color-scheme: dark) {
  .custom-minigrid {
    border: 1px solid #737373;
  }

  .custom-minigrid tbody td:not(:first-child) {
    border-color: #737373;
    background-color: #949292;
  }
}
