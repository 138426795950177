.custom_label {
  font-size: 14px;
  font-weight: 500;
}
.tdCustom {
  padding: 2px;
  margin: 10px;
  white-space: nowrap;
  border-spacing: 5px;
  border: 5px;
}
.button_custom {
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #ff385c;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 0px;
}
.datePickerDis {
  font-size: 18px;
  padding: 16px 16px 16px 0px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}
.button_custom_action_cupom {
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #ff385c;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  width: 180px;
  margin-left: 0px;
}
